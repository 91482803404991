import styled from 'styled-components';

export const StyledFormControl = styled.input`
  display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    height: 51px !important;
    background-color: white;
    border: 1px solid #2D995F;


  @media (max-width: 992px) {
    // padding: 10px;
    width: 100% !important;
    font-size: 14px;
    margin-bottom: 15px;
    border: 1px solid #aaa;
    border-radius: 2px;
  }
`;

export const StyledFormSelect = styled.select`
   --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-image: var(--bs-form-select-bg-img),var(--bs-form-select-bg-icon,none);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    

  @media (max-width: 992px) {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #aaa;
    border-radius: 2px;
    margin-top: 0px;
  }
`;

export const StyledButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 4px;

  @media (max-width: 992px) {
    padding: 8px 16px;
    font-size: 14px;
    margin-bottom: 125px;
    margin-top: 10px;
  }
`;
