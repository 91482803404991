import React from 'react';
import classes from './OgunCompleted.module.css';
// import classes from './ModuleSelector.module.css';

import oguninvest from '../../assets/urban/banners.svg';
import oguninvest2 from '../../assets/urban/oguninvest.svg';
import zoom from '../../assets/urban/zoom.png';
import facebook from '../../assets/urban/face.png';
import instag from '../../assets/urban/insta.png';
import oguninvest22 from '../../assets/urban/oguninvest.svg';


const OgunCompleted = () => {
  return (
    <div className={classes.moduleselector}>
        <img src={oguninvest} alt="Icon" className={classes.moduleicon} />
    <div className={classes.innerss}>
        <p>Dear <b>addfadf,</b></p>
        <p>Thank you for registering for the Ogun State Investment Webinar organized in partnership with the South Africa-Nigeria Business Chamber.</p>
        <p>We are delighted to confirm your registration. Kindly join the meeting through the following links:</p>
        <div className={classes.linksicons}>
            <div className={classes.linksleft}>
            <img src={zoom} alt="Icon" className={classes.moduleicon1} />
            </div>
            <div className={classes.linksright}>
                <p>https://wtryeryerquutwriwryititiyytiywiwi</p>
            </div>
        </div>
        <div className={classes.linksicons}>
            <div className={classes.linksleft}>
            <img src={facebook} alt="Icon" className={classes.moduleicon1} />
            </div>
            <div className={classes.linksright}>
                <p>https://wtryeryerquutwriwryititiyytiywiwi</p>
            </div>
        </div>
        <div className={classes.linksicons}>
            <div className={classes.linksleft}>
            <img src={instag} alt="Icon" className={classes.moduleicon1} />
            </div>
            <div className={classes.linksright}>
                <p>https://wtryeryerquutwriwryititiyytiywiwi</p>
            </div>
        </div>
        <p className={classes.para}>Best regards</p>
        <img src={oguninvest22} alt="Icon" className={classes.moduleicon112} />
        <p>Ogun State Investment Promotion and Facilitation Agency</p>
        <p><b>Office Address:</b> 4th Floor, Opic House, Isheri, Lagos-Ibadan, Expressway Ogun State. Block D, Room 115, State Secretariat, Oke-Mosan, Abeokuta, Ogun State.</p>
    </div>
    </div>
  );
};

export default OgunCompleted;