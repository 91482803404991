import React, {useState, useEffect} from 'react';
import './landingPage.css';
import NewFooter from './Components/NewFooter';
import logo from './assets/logo.png';
import ministry from './assets/Rectangle 21.png';
import { NavLink } from 'react-router-dom';
import MDAHeaderNav from './Components/MDAHeaderNav/MDAHeaderNav';
import NewFooter2 from './Components/NewFooter2';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL, TOKEN } from './BaseUrl/BaseUrl';
import { Spinner } from 'react-bootstrap';


function Mdas() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalEntries, setTotalEntries] = useState("");
    const [totalPages, setTotalPages] = useState("");
    const [isloading, setIsLoading] = useState(false);
    const [mdaLoading, setMdaLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [mdaDetails, setMdaDetails] = useState([]);
const navigate = useNavigate();
    const handleNavigateToHome = () => {
        navigate('/')
      }


      const fetchMDAs = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(`${BASE_URL}/get-mda-by-type`, {
            headers: {
              'Authorization': `Bearer ${TOKEN}`,
              'Content-Type': 'application/json'
          },
            params: {
                mda_type: "1",
                page: currentPage
            }
        });
          const results = response.data?.data?.data;
          const resultx = response.data?.data?.total;
          setTotalEntries(resultx);
          setTableData(results);
          const total = response.data?.data?.last_page || 1;
          setTotalPages(total);
          console.log(results);
        } catch (error) {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setTableData([]);
        } finally {
          setIsLoading(false);
        }
      };

      const handleFetchMda = async (id) => {
        setMdaLoading(true);
        try {
          const response = await axios.get(`${BASE_URL}/get-mda-details`, {
            headers: {
                'Authorization': 'Bearer 273|Kye0HqGFFSto5HnLgLSpLGSV9kPP7J2tsLTfQUOV',
                'Content-Type': 'application/json'
            },
            params: {
                id: id,
            }
        });
          const res = response.data?.data;
          setMdaDetails(res);
          navigate('/ministry_details', { state: { selectedMda: res } });
          console.log(res);
        } catch (error) {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setMdaDetails([]);
        } finally {
          setMdaLoading(false);
        }
      };

      useEffect(() => {
        
          fetchMDAs();
       
      }, [currentPage]);

      const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
        window.scrollTo(0, 0); 
    };
    
    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
        window.scrollTo(0, 0); 
    };
      
    

    return (
        <div>
            <MDAHeaderNav />
            {/* <div className='Mda_Header'>
              <NavLink to={'/'} style={{textDecoration: 'none'}}>  <div className="logoTxt">
                 <img src={logo} className="logo" />
                    <p className="ogun">Ogun State<br />Government </p>
                </div></NavLink>

                <div className="Mda_opts">
                <p>Ministries</p>
                   
                    <NavLink  to={'/Departments'}>
                        <button>
                            <p>Departments </p>
                        </button>
                    </NavLink>
                    
                    
                    <NavLink  to={'/page2'}>
                        <button>
                            <p>Agencies </p>
                        </button>
                    </NavLink>
                    
                </div>
            </div> */}

           

            <div className='Mda_mainCotainer'>
            <div style={{marginTop: "10rem"}}/>
                   
            {isloading ? (
    <Spinner size="sm" className='space-top'/>
) : (
    tableData.map((item, index) => (
        <div onClick={() => handleFetchMda(item.id)} key={index} className="mda_container" style={{marginTop: 10 }}>
            <div>
                <img src={ministry} className="ministry" alt="Ministry" />
            </div>
            <div className="textInMda">
                <p className="textInMdaMinistry">{item.name}</p>
                <p className="textInMdaunder">
                    {item.about_mda}
                </p>
                <p className="viewMinistry">View Ministry...</p>
            </div>
        </div>
    ))
)}
<div className='bottom-ended'>
 <div className='endded'>
                             
                             <div style={{ display: 'flex' }}>
                               <button
                                 style={{ border: 'none', textAlign: "center", backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'inter', color: '#000', marginRight: 10, cursor: "pointer" }}
                                 onClick={handlePrevPage}
                                 disabled={currentPage === 1}
                               >
                                 Previous
                               </button>
                               {[...Array(totalPages)].map((_, page) => {
                                 // Show only 5 pages or less if available
                                 if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                   return (
                                     <button
                                       key={page + 1}
                                       style={{
                                         marginLeft: '0.4rem',
                                         marginRight: '0.4rem',
                                         fontSize: '14px',
                                         fontFamily: 'nunito',
                                         fontWeight: 400,
                                         color: page + 1 === currentPage ? '#ffffff' : '#000',
                                         backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                         height: '2.5rem',
                                         borderRadius: '89px',
                                         padding: '0.5rem',
                                         border: 'none',
                                         width: '40px',
                                         cursor: "pointer",
                                         textAlign: "center",
                                         fontFamily: "inter"
                                       }}
                                       onClick={() => {
                                           setCurrentPage(page + 1);
                                           window.scrollTo(0, 0); 
                                       }}
                                     >
                                       {page + 1}
                                     </button>
                                   );
                                 }
                                 return null;
                               })}
                               <button
                                 style={{ border: 'none', textAlign: "center", backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'inter', color: '#000', marginRight: 10, cursor: "pointer" }}
                                 onClick={handleNextPage}
                                 disabled={currentPage === totalPages}
                               >
                                 Next
                               </button>
                             </div>
                           </div>
                           </div>
                

            </div>
<NewFooter2 />
            
        </div>
    );
}

export default Mdas;
