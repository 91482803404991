import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, Link } from 'react-router-dom';
import UrbanHeader from "../../Components/urbanHeader/UrbanHeader";
// import { Form, Row, Col } from 'react-bootstrap';
import { StyledFormControl, StyledFormSelect, StyledButton } from './StyledComponents';
import NewFooter from "../../Components/NewFooter";
import classes from "./OgunInvestRegistration.module.css";
import NewFooter2 from "../../Components/NewFooter2";
import NewsIcon from '../../assets/olumo.png'
import HeaderNav2 from "../../Components/HeaderNav/HeaderNav2";
// import VideoFile from '../../video/landingpage.mp4';
import IMGFile from '../../assets/urban/logs.png'
import Comm1 from '../../assets/urban/oga.png'
import Pic1 from '../../assets/urban/pic1.png'
import Pic2 from '../../assets/urban/pic2.png'
import Pic3 from '../../assets/urban/pic3.png'
import Pic4 from '../../assets/urban/pic4.png'
import Pic5 from '../../assets/urban/pic5.png'
import Pic6 from '../../assets/urban/pic6.png'
import axios from "axios";
import styled from 'styled-components';
import { Spinner, Button, Accordion } from "react-bootstrap";
import { BASE_URL, TOKEN } from "../../BaseUrl/BaseUrl";
import logo from '../../assets/urban/ogLogss.svg';
import Arrow from '../../assets/carousels/ban1.png';
import Arrow1 from '../../assets/carousels/ban2.png';
import Arrow2 from '../../assets/carousels/ban3.png';
import Service1 from '../../assets/carousels/service1.png';
import Service2 from '../../assets/carousels/service2.png';
import Service3 from '../../assets/carousels/service3.png';
import Down1 from '../../assets/carousels/down1.png';
import Down2 from '../../assets/carousels/down2.png';
import Down3 from '../../assets/carousels/down3.png';
import Down4 from '../../assets/carousels/ir.png';
import Icon1 from '../../assets/executives-icon/icon.png';
import Icon11 from '../../assets/landingpage/ic1.png'
import Icon2 from '../../assets/landingpage/ic2.png'
import Icon3 from '../../assets/landingpage/ic3.png'
import HalfMap from '../../assets/halfMap.svg';
import ImageFlyer from '../../assets/urban/newban.jpg';
import footerLogo from "../../assets/Footer-logo.svg";
import { Form, Row, Col, } from 'react-bootstrap';
import { toast } from "react-toastify";
import Swal from "sweetalert2";


export default function OgunInvestRegistration() {
    const [loading, setLoading] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);
    const navigate = useNavigate();
    const [documents, setDocuments] = useState([]);
    const [clickedMenu, setClickedMenu] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedProvince, setSelectedProvince] = useState('');
    const [selectedSector, setSelectedSector] = useState('');
    const [selectedSector1, setSelectedSector1] = useState('');
    const [company, setCompany] = useState('');
    const [designation, setDesignation] = useState('');
    const [error, setError] = useState(null);
    const [stateDisabled, setStateDisabled] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessage1, setErrorMessage1] = useState('');
    const homeRef = useRef(null);
  



    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };


    const handleNavigateToHome = () => {
        navigate('/')
    }

  

    function formatDate(dateString) {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const date = new Date(dateString);
        const dayOfWeek = days[date.getDay()];
        const month = months[date.getMonth()];
        const dayOfMonth = date.getDate();
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        const formattedDate = `${dayOfWeek}, ${month}, ${dayOfMonth} ${year} at ${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
        return formattedDate;
    }

   

   

    const handleGoBack = () => {
        navigate(-1);
    }

   
    useEffect(() => {
        const fetchCountries = async () => {
          try {
            const response = await axios.get('https://restcountries.com/v3.1/all');
            const sortedCountries = response.data.sort((a, b) => {
              // Compare the country names alphabetically
              const nameA = a.name.common.toUpperCase();
              const nameB = b.name.common.toUpperCase();
              return nameA.localeCompare(nameB);
            });
      
            // Filter out Nigeria and South Africa from the sorted list
            const nigeria = sortedCountries.find(country => country.name.common === 'Nigeria');
            const southAfrica = sortedCountries.find(country => country.name.common === 'South Africa');
            const otherCountries = sortedCountries.filter(
              country => country.name.common !== 'Nigeria' && country.name.common !== 'South Africa'
            );
      
            // Place Nigeria and South Africa at the beginning of the list
            const finalCountries = [nigeria, southAfrica, ...otherCountries];
      
            setCountries(finalCountries);
            setLoading(false);
          } catch (err) {
            setError('Failed to fetch countries');
            setLoading(false);
          }
        };
      
        fetchCountries();
      }, []);
      
      const nigerianStates = [
        { code: "OG", name: "Ogun" },
        { code: "LA", name: "Lagos" },
        { code: "AB", name: "Abia" },
        { code: "AD", name: "Adamawa" },
        { code: "AK", name: "Akwa Ibom" },
        { code: "AN", name: "Anambra" },
        { code: "BA", name: "Bauchi" },
        { code: "BY", name: "Bayelsa" },
        { code: "BE", name: "Benue" },
        { code: "BO", name: "Borno" },
        { code: "CR", name: "Cross River" },
        { code: "DE", name: "Delta" },
        { code: "EB", name: "Ebonyi" },
        { code: "ED", name: "Edo" },
        { code: "EK", name: "Ekiti" },
        { code: "EN", name: "Enugu" },
        { code: "FC", name: "FCT" },
        { code: "GO", name: "Gombe" },
        { code: "IM", name: "Imo" },
        { code: "JI", name: "Jigawa" },
        { code: "KA", name: "Kaduna" },
        { code: "KA", name: "Kano" },
        { code: "KT", name: "Katsina" },
        { code: "KE", name: "Kebbi" },
        { code: "KO", name: "Kogi" },
        { code: "KW", name: "Kwara" },
        
        { code: "NA", name: "Nasarawa" },
        { code: "NI", name: "Niger" },
       
        { code: "ON", name: "Ondo" },
        { code: "OS", name: "Osun" },
        { code: "OY", name: "Oyo" },
        { code: "PL", name: "Plateau" },
        { code: "RI", name: "Rivers" },
        { code: "SK", name: "Sokoto" },
        { code: "TA", name: "Taraba" },
        { code: "YO", name: "Yobe" },
        { code: "ZA", name: "Zamfara" }
       
      ];

      const southAfricanProvinces = [
        { code: "WC", name: "Western Cape" },
        { code: "EC", name: "Eastern Cape" },
        { code: "NC", name: "Northern Cape" },
        { code: "FS", name: "Free State" },
        { code: "KZN", name: "KwaZulu-Natal" },
        { code: "GT", name: "Gauteng" },
        { code: "LP", name: "Limpopo" },
        { code: "MP", name: "Mpumalanga" },
        { code: "NW", name: "North West" },
      ];

      const businessSectors = [
        { code: "1", name: "Agriculture" },
        { code: "2", name: "Construction" },
        { code: "3", name: "Manufacturing" },
        { code: "4", name: "Retail" },
        { code: "5", name: "Healthcare" },
        { code: "6", name: "Information Technology" },
        { code: "7", name: "Finance" },
        { code: "8", name: "Education" },
        { code: "9", name: "Transportation" },
        { code: "10", name: "Energy" },
        { code: "11", name: "Real Estate" },
        { code: "12", name: "Entertainment" },
        { code: "13", name: "Telecommunications" },
        { code: "14", name: "Hospitality" },
        { code: "15", name: "Legal Services" },
        { code: "16", name: "Tourism" },
        { code: "17", name: "Insurance" },
        { code: "18", name: "Public Sector" },
        { code: "19", name: "Agribusiness" },
        { code: "20", name: "Consulting" },
        { code: "21", name: "Non-Profit" },
        { code: "22", name: "Media" }
      ];

      const interestedSectors = [
        { code: "1", name: "Agriculture" },
        { code: "2", name: "Manufacturing" },
        { code: "3", name: "Energy" },
        { code: "4", name: "Transport" },
        { code: "5", name: "Healthcare" },
        { code: "6", name: "Mining" },
        { code: "7", name: "Housing" },
        { code: "8", name: "Information and Communication Technology" },
       
      ];
      

      const handleCountryChange = (event) => {
        const selectedCountry = event.target.value;
        setSelectedCountry(selectedCountry);
        // Enable or disable the state dropdown based on selected selectedCountry
        setStateDisabled(!(selectedCountry === 'Nigeria' || selectedCountry === 'South Africa'));
      };
      const handleStateChange = (event) => {
        const state = event.target.value;
        setSelectedState(state);
       
      };
      const handleSectorChange = (event) => {
        setSelectedSector(event.target.value);
       
      };
      const handleSectorChange1 = (event) => {
        setSelectedSector1(event.target.value);
       
      };
    
      const getStateOptions = () => {
        if (selectedCountry === 'South Africa') {
          return southAfricanProvinces;
        }
        if (selectedCountry === 'Nigeria') {
          return nigerianStates;
        }
        return [];
      };
    
      const getStateLabel = () => {
        if (selectedCountry === 'South Africa') {
          return 'Select Province';
        }
        if (selectedCountry === 'Nigeria') {
          return 'Select State';
        }
        return 'Select State';
      };
    

      console.log(selectedCountry);

//       const Input = styled.input`
 

//   @media (max-width: 600px) {
//     padding: 8px;
//     font-size: 14px;
//     border: 1px solid #aaa;
//     border-radius: 2px;
//   }
// `;

        
      //   return 'Select State';
      // };
    

      const handleRegistration = async () => {
        setCreateLoading(true);
        try {
          console.log(selectedSector, selectedCountry, selectedSector1, selectedState);
        
            // console.log(fullName, email, phoneNumber, selectType, selectedToggle);
      
          const response = await axios.post(
            `${BASE_URL}/webinar_registration`,
            {
              business_sector: selectedSector,
              company_name: company,
              country: selectedCountry,
              designation: designation,
              email: email,
              first_name: firstName,
              last_name: lastName,
              sector_interested_in: selectedSector1,
              state_of_origin: selectedState
            },
            // { headers }
          );
          console.log(response)
          setSelectedCountry('');
          setSelectedState('');
          setSelectedSector('');
          setSelectedSector1('');
          setEmail('');
          setFirstName('');
          setLastName('');
          setDesignation('');
          setCompany('');
          // navigate(-1);
         
          // return
          // Swal.fire({
          //   icon: 'success',
          //   title: 'Success',
          //   text: response.data.message,
          // });
        toast.success(response.data.message);
          console.log(response.data);
      
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
                errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
                errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
                errorMessage = JSON.stringify(error.response.data.message);
            }
            Swal.fire({
              icon: 'error',
              title: 'Failed',
              text: errorMessage,
            });
            // toast.error(errorMessage);
            console.log(error);
        }
        } finally {
          setCreateLoading(false);
        }
      };
      
      const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const [isValidEmail, setIsValidEmail] = useState(true);

    const handleEmailBlur = () => {
        const isValid = validateEmail(email);
        setIsValidEmail(isValid);
        if (!isValid) {
          setErrorMessage1('Invalid email');
        } else {
            setErrorMessage1('');
        }
    };

    const isDisabled =   !email || !firstName || !lastName
    || !selectedCountry || !selectedSector
    || !selectedSector1 || !designation || !isValidEmail ;

    return (
        <div>
            {/* <HeaderNav2 onMenuClick={handleMenuClick} /> */}
            {/* <div className={classes.headerSection} >
               
                <div className={classes.ognheadline}></div>
                <nav className={classes.nav}> */}
                    {/* <div className={classes.ogninfo}>
                        <p>Mail us at: <span>info@ogunstate.gov.ng</span></p>
                        <p>or call us at: <span>+23400000000000      +23401110000222</span></p>
                    </div> */}
                    {/* <div className={classes.navContainer} >
                        <NavLink to={'/'} className={classes.navLogoWeb}><img src={logo} className={classes.logoicon} /></NavLink>
                        <ul className={`${classes.mainMenu} ${isMenuOpen ? '' : classes.menuOpen}`}>
                            <li className={classes.linkList}> */}
                                {/* <li to={'#'} >Home</li> */}
                                {/* <li to={'#'} >About Us</li>
                                <li to={'#'} >Opportunities </li>
                                <li to={'#'} >Process</li>
                                <li to={'#'} >News</li>
                                <li to={'#'} >Resources</li>
                                <li to={'#'} >FAQs</li> */}
                            {/* </li>
                            <li className={classes.navItem}>
                            </li>
                            <button  className={classes.mdaButton}>Contact Us</button>
                        </ul> */}
                        {/* <div className={classes.harmborgers} onClick={toggleMenu}>
                            {isMenuOpen ? (
                                <span className={classes.closs}>
                                    <i className='bx bx-x'></i>

                                </span>
                            ) : (
                                <span className={classes.open}>
                                    <i className='bx bx-menu'></i>
                                </span>
                            )}
                        </div>
                    </div>
                </nav>
            </div> */}
            <div ref={homeRef} className={classes.generalBody}>
                <div className={classes.bodyContainer}>
                    <img src={ImageFlyer} alt='banner' className={classes.flyerImage} />

                </div>
                <div className={classes.wrapper}>
                    <p className={classes.fillDet}>Please Fill in your details below</p>
                    <p className={classes.fillDet1}>All fields marked * are required</p>

                </div>
                <div className={classes.formDiv}>
                <Form>
      <Row className="mb-3">
      <Col xs={12} md={6}>
          <Form.Group controlId="formInput3">
            <Form.Label>Email Address <span className={classes.span}>*</span></Form.Label>
            <Form.Control style={{border: !isValidEmail ? "1px solid red" : "1px solid #2D995F"}} onBlur={handleEmailBlur} value={email}  onChange={(e) => setEmail(e.target.value)} as={StyledFormControl} className={classes.formCont} type="text" placeholder="Enter your email address" />
             <p style={{color: "red"}}>{errorMessage1}</p> 
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId="formInput1">
            <Form.Label>First Name <span className={classes.span}>*</span></Form.Label>
            <Form.Control value={firstName}  onChange={(e) => setFirstName(e.target.value)} className={classes.formCont} type="text" placeholder="Enter your first name" />
          </Form.Group>
        </Col>
       
      </Row>

      <Row className="mb-3">
      <Col xs={12} md={6}>
          <Form.Group controlId="formInput2">
            <Form.Label>Last Name <span className={classes.span}>*</span></Form.Label>
            <Form.Control value={lastName}  onChange={(e) => setLastName(e.target.value)} className={classes.formCont} type="text" placeholder="Enter your last name" />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId="formSelect2" className={classes.formgroupCont}>
            <Form.Label>Select Country <span className={classes.span}>*</span></Form.Label>
            <Form.Select value={selectedCountry} as={StyledFormSelect} className={classes.formCont} onChange={handleCountryChange}>
              <option value="" disabled >Select your country</option>
              {countries.map((country) => (
          <option key={country.cca2} value={country.name.common}>
            {country.name.common}
          </option>
        ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={12} md={6}>
          <Form.Group controlId="formSelect1">
          <Form.Label>{getStateLabel()} </Form.Label>
            <Form.Select value={selectedState} onChange={handleStateChange} as={StyledFormSelect} className={classes.formCont} disabled={stateDisabled}>
            <option value="" disabled >{getStateLabel()}</option>
                    {getStateOptions().map((state) => (
                      <option key={state.code} value={state.name}>
                        {state.name}
                      </option>
                    ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId="formInput4" className={classes.formgroupCont}>
            <Form.Label>Company Name <span className={classes.span}>*</span></Form.Label>
            <Form.Control value={company}  onChange={(e) => setCompany(e.target.value)} as={StyledFormControl}  className={classes.formCont} type="text" placeholder="Enter your company name" />
          </Form.Group>
        </Col>
        
        {/* <Col xs={12} md={6}>
          <Form.Group controlId="formSelect3">
            <Form.Label>Select Local Government</Form.Label>
            <Form.Select className={classes.formCont}>
              <option value="" disabled selected>Select Local Government</option>
              <option value="1">Local Government 1</option>
              <option value="2">Local Government 2</option>
              <option value="3">Local Government 3</option>
            </Form.Select>
          </Form.Group>
        </Col> */}
      </Row>

      <Row className="mb-3">
      <Col xs={12} md={6}>
          <Form.Group controlId="formInput5">
            <Form.Label>Designation <span className={classes.span}>*</span></Form.Label>
            <Form.Control value={designation}  onChange={(e) => setDesignation(e.target.value)} as={StyledFormControl} className={classes.formCont} type="text" placeholder="Enter your designation" />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId="formSelect4">
            <Form.Label>Business Sector <span className={classes.span}>*</span></Form.Label>
            <Form.Select value={selectedSector} onChange={handleSectorChange} as={StyledFormSelect}  className={classes.formCont}>
              <option value="" disabled >Select Business Sector</option>
              {businessSectors.map((country) => (
          <option key={country.code} value={country.name}>
            {country.name}
          </option>
        ))}
            </Form.Select>
          </Form.Group>
        </Col>

        
        
      </Row>

      <Row className="mb-3">
      <Col xs={12} md={12}>
          <Form.Group controlId="formSelect5">
            <Form.Label>What sector are you interested in investing in? <span className={classes.span}>*</span></Form.Label>
            <Form.Select value={selectedSector1} onChange={handleSectorChange1} as={StyledFormControl} className={classes.formCont}>
              <option value="" disabled >Select Business Sector</option>
              {interestedSectors.map((country) => (
          <option key={country.code} value={country.name}>
            {country.name}
          </option>
        ))}
            </Form.Select>
          </Form.Group>
        </Col> 
        
      </Row>

      <Row className="justify-content-md-center">
        <Col md="auto">
          <div
          disabled={isDisabled}
          
           as={StyledButton}  variant="success" className={classes.buttonForm} onClick={handleRegistration} >
          {createLoading ? (
                  <>
                    <Spinner size='sm' />
                    <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                  </>
                ) : (
                  "Submit"
                )}
          </div>
        </Col>
      </Row>
    </Form>
                </div>













































            </div>
            <div className={`${classes.wrapper} ${classes.FooterBg2}`}>
                <div className={classes.containers}>
                    <img src={HalfMap} alt="Ogun Map" className={classes.halfmap} />
                    <div className={`${classes.container} ${classes.mainFooter}`}>
                        {/* <div className={classes.finalDiv}> */}
                        <div onClick={handleNavigateToHome} className={classes.footerLogoCont}>
                            <div className={classes.footerLogo}>
                                <img src={footerLogo} alt="Ogun Logo" />
                            </div>
                            <p style={{ color: 'white' }} >
                                It is peopled predominantly by the Egbas, Ijebus, Yewas, Remos and Aworis; - who belong to the main Yoruba ethnic group.  It also has sub-groups, namely, Ketu, Ikale, Ilaje, Ohori, Anago and Egun. There are also significant numbers of Nigerians from other parts of the country as well as foreign nationals resident in the State.

                                Ogun State welcomes you!
                            </p>
                        </div>

                        <div className={`${classes.footerConts} ${classes.footerContsnav}`}>
                            {/* <h5 className={classes.name}>Link</h5> */}
                            <div className={classes.footList}>
                                {/* <li to={'#'} onClick={() => scrollToSection(homeRef)}>Home</li>
                                <li to={'#'} >About Us</li>
                                <li to={'#'} >Opportunities </li>
                                <li to={'#'} >Process</li>
                                <li to={'#'} >News</li>
                                <li to={'#'} >Resources</li>
                                <li to={'#'} >FAQs</li> */}
                                {/* <NavLink to={'/'} ><p>Home</p></NavLink> */}
                                {/* <NavLink to={'/'} ><p>Event</p></NavLink>
          <NavLink to={'/'} ><p>Blog</p></NavLink>
          <NavLink to={'/'} ><p>News</p></NavLink> */}
                                {/* <NavLink to={'/ogstep_documents'} ><p>Contracts & Adverts</p></NavLink> */}
                            </div>
                        </div>

                        {/* <div className={classes.footerConts}>
          <h5 className={classes.name}>MDAs</h5>
          <div>
            <p>Bureau of Public service reforms</p>
            <p>Ministry of Education, Science and Technology</p>
            <p>Ministry of Budget and Planning</p>
            <p>Ministry of Political Affairs and Administration</p>
          </div>
        </div> */}
                        <div className={classes.footerConts}>
                            <h5 className={classes.name}>Contact Us</h5>
                            <div>
                                <p>4th Floor, OPIC House, 
Lagos-Ibadan Expressway, Isheri, Ogun State.</p>
                            </div>
                            <div style={{marginTop: -10}}>
                                <p>Block D,Room 115, State Secretariat Oke-Mosan, Abeokuta, Ogun State.</p>
                            </div>
                                <p style={{marginTop: -15}}><span style={{color: "green", fontWeight: 700, }}>E-mail: </span>investment@ogunstate.gov.ng</p>
                            <div>
                               
                                <p style={{marginTop: -15}}><span style={{color: "green", fontWeight: 700}}>Phone:</span>+234(0)9091661337 , +234(0)8169213747</p>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div className={classes.footerText}>
                <p className={classes.subFooter}>© OGUN STATE GOVERNMENT </p>
                <p className={classes.subFooter1}>All Rights Reserved. Terms of Use and Privacy Policy</p>
            </div>
        </div >
    )
}
